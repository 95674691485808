<script lang="ts" setup>
import { ref, watch, onMounted } from 'vue';
import { RouteMeta, useRoute } from 'vue-router';
import { useStore } from './store';
import { inspectlet } from './services/inspectlet-service';
import ToastsContainer from './components/common/toasts/toasts-container.vue';
import GlobalErrorModal from './components/common/modal/global-error-modal.vue';
import GlobalReleaseNotesModal from './components/common/modal/global-release-notes-modal.vue';

const route = useRoute();
const store = useStore();

const background = ref({
  useThisBackgroundStyle: false,
  background: '',
  backgroundColor: '',
});

function setAppBackground(routeMeta: RouteMeta) {
  if (routeMeta?.backgroundColor) {
    background.value.backgroundColor = routeMeta.backgroundColor as string;
  } else {
    background.value.backgroundColor = '';
  }
}

onMounted(async () => {
  const user = await store.currentUserInfo.emailAddress;
  await inspectlet(user);
});

watch(
  () => route.meta,
  async (meta) => {
    setAppBackground(meta);
  }
);
</script>

<template>
  <GlobalReleaseNotesModal />
  <GlobalErrorModal />
  <div
    class="app-wrapper"
    :style="background.backgroundColor === '' ? '' : background"
  >
    <router-view name="NavigationBarVerticalView" />
    <router-view :key="route.path" />
    <ToastsContainer />
  </div>
</template>

<style lang="scss">
@import './assets/css/global.scss';
@import './assets/css/theme.css';

body {
  margin: 0;
}

.app-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: hidden;
  overflow-x: hidden;
}

#app {
  font: $font;
  font-family: $fontFamilies;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $fontColor;
  background: transparent
    linear-gradient(
      162deg,
      #171917 0%,
      #1e201e 32%,
      #262926 52%,
      #2e312d 78%,
      #363935 100%
    )
    0% 0% no-repeat padding-box;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

button,
input,
select {
  font: $font;
  font-family: $fontFamilies;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $fontColor;
}
</style>
