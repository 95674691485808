import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import {
  getAllReleaseNotes,
  hasUserAcknowledgedLatestVersion,
} from '../services/aggregation-service';
import { ReleaseNote } from '../models/system';
import { useGlobalErrorsStore } from '../store/useGlobalErrorsStore';
import { ReleaseNoteMainNote } from '../models/release-note';

export const useReleaseNotesStore = defineStore('releaseNotes', () => {
  const releaseNotesArray = ref<ReleaseNote[]>([]);
  const _hasUserAcknowledgedLatestReleaseNote = ref(false);
  const currentlyViewedNote = ref<ReleaseNote | undefined>();
  const releaseNotesModalButton = ref<'confirm' | 'close'>('confirm');
  const showReleaseNotesModal = ref(false);

  const globalErrorStore = useGlobalErrorsStore();

  const modalTitle = computed(() => {
    return currentlyViewedNote.value?.name + ' Release Notes';
  });
  const modalBody = computed(() => {
    return currentlyViewedNote.value?.notes;
  });

  const closeModal = () => {
    currentlyViewedNote.value = undefined;
    showReleaseNotesModal.value = false;
  };

  const openModal = (releaseNote: ReleaseNote) => {
    currentlyViewedNote.value = releaseNote;
    showReleaseNotesModal.value = true;
  };

  const init = async () => {
    try {
      releaseNotesArray.value = await getAllReleaseNotes();
      releaseNotesArray.value = releaseNotesArray.value.map((note) => {
        let jsonNotes;
        try {
          jsonNotes = JSON.parse(note.notes);
        } catch (e) {
          console.error('Error parsing release notes', e);
          globalErrorStore.showGenericError(e);
        }
        return {
          ...note,
          notes: jsonNotes as ReleaseNoteMainNote[],
        };
      });
    } catch (e) {
      console.error('Error fetching release notes', e);
      globalErrorStore.showGenericError(e);
    }

    let resp;
    try {
      resp = await hasUserAcknowledgedLatestVersion();
      _hasUserAcknowledgedLatestReleaseNote.value = resp.hasAckdLatestVersion;
      const latestReleaseNote = resp.latestReleaseVersion;

      if (latestReleaseNote) {
        let jsonNotes;
        try {
          jsonNotes = JSON.parse(latestReleaseNote.notes);
          latestReleaseNote.notes = jsonNotes as ReleaseNoteMainNote[];
        } catch (e) {
          console.error('Error parsing release notes', e);
          globalErrorStore.showGenericError(e);
        }
        currentlyViewedNote.value = latestReleaseNote;
        if (!_hasUserAcknowledgedLatestReleaseNote.value && latestReleaseNote) {
          openModal(latestReleaseNote);
        }
      }
    } catch (e) {
      console.error('Error checking user acknowledgment', e);
      globalErrorStore.showGenericError(e);
    }
  };

  return {
    showReleaseNotesModal,
    modalTitle,
    modalBody,
    currentlyViewedNote,
    releaseNotesArray,
    releaseNotesModalButton,
    closeModal,
    init,
    openModal,
  };
});
