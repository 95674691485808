<script lang="ts" setup>
import ModalPopup from './modal-popup.vue';
import { useReleaseNotesStore } from '../../../store/useReleaseNotesStore';
import BaseBtn from '../../../components/common/button/base-btn.vue';
import { onMounted, onUnmounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { acknowledgeReleaseVersion } from '../../../services/account-mgt-service';
import { useGlobalErrorsStore } from '../../../store/useGlobalErrorsStore';
import ReleaseNoteMainNote from '../release-notes/release-note-main-note.vue';

const releaseNotesStore = useReleaseNotesStore();
const globalErrorStore = useGlobalErrorsStore();
const releaseNotesBodyContainer = ref<HTMLElement | null>(null);

const isConfirmButtonDisabled = ref(true);

async function handleConfirmButtonClicked(
  releaseVersionId: string | undefined
) {
  if (!releaseVersionId) {
    globalErrorStore.showGenericError('releaseVersionId undefined');
    return;
  }
  try {
    await acknowledgeReleaseVersion(releaseVersionId);
    releaseNotesStore.closeModal();
  } catch (e) {
    globalErrorStore.showGenericError(e);
  }
}

const isScrollWithin5pxOfBottom = (target: HTMLElement) => {
  const isWithin20pxOfBottom =
    target.scrollHeight - target.scrollTop <= target.clientHeight + 5;

  return isWithin20pxOfBottom;
};

const enablingConfirmButton = () => {
  isConfirmButtonDisabled.value = false;
  unmount();
};

const handleScroll = (event: Event) => {
  if (event.target) {
    isConfirmButtonDisabled.value = !isScrollWithin5pxOfBottom(
      event.target as HTMLElement
    );
  }
};

const unmount = () => {
  if (interval.value) {
    clearInterval(interval.value);
  }
  releaseNotesBodyContainer.value?.removeEventListener('scroll', handleScroll);
};

const interval = ref();
onMounted(() => {
  interval.value = setInterval(() => {
    if (releaseNotesBodyContainer.value) {
      if (isScrollWithin5pxOfBottom(releaseNotesBodyContainer.value)) {
        enablingConfirmButton();
      }
    }
  }, 500);
  releaseNotesBodyContainer.value?.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  unmount();
});

const route = useRoute();
</script>

<template>
  <ModalPopup
    v-if="releaseNotesStore.showReleaseNotesModal && route.name !== 'eula'"
    :title="releaseNotesStore.modalTitle"
  >
    <template #body>
      <div class="release-notes-body-container" ref="releaseNotesBodyContainer">
        <template v-if="releaseNotesStore.currentlyViewedNote?.notes">
          <ReleaseNoteMainNote
            v-for="note in releaseNotesStore.currentlyViewedNote?.notes"
            :main-note="note"
          />
        </template>
        <template v-else>{{ $t('releaseNotes.noNotesAvailable') }}</template>
      </div>
    </template>
    <template #footer>
      <BaseBtn
        v-if="releaseNotesStore.releaseNotesModalButton === 'confirm'"
        class="btn-primary"
        @click="
          handleConfirmButtonClicked(
            releaseNotesStore.currentlyViewedNote!.releaseVersionId
          )
        "
        :disabled="isConfirmButtonDisabled"
      >
        {{ $t('global.button.confirm') }}
      </BaseBtn>
      <BaseBtn
        v-if="releaseNotesStore.releaseNotesModalButton === 'close'"
        class="btn-primary"
        @click="releaseNotesStore.closeModal()"
      >
        {{ $t('global.button.close') }}
      </BaseBtn>
    </template>
  </ModalPopup>
</template>

<style lang="scss">
.release-notes-body-container {
  max-height: 500px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
</style>
