export interface ReleaseNoteMainNote {
  listMarker: string;
  title: string;
  subnotes: Array<ReleaseNoteSubNote>;
}

export interface ReleaseNoteSubNote {
  indentType: 'single' | 'double' | 'triple';
  listMarker: string;
  description?: string;
  media?: {
    identifier: ReleaseNoteMediaIdentifier | string;
    type: 'image' | 'video';
    styleProperties?: Record<string, string>;
  };
}

export enum ReleaseNoteMediaIdentifier { // used to ensure keys do not overlap
  PediatricWorkflowVideo = 'PediatricWorkflowVideo',
  AxialRotationOfVertebraeScreenshot = 'AxialRotationOfVertebraeScreenshot',
  SegmentalLordosisScreenshot = 'SegmentalLordosisScreenshot',
  MoreParametersScreenshot = 'MoreParametersScreenshot',
  MisRodSupportScreenshot = 'MisRodSupportScreenshot',
}
