<script lang="ts" setup>
import VertRotationChartImage from '../../../assets/img/release_notes_arc.jpg';
import SegmentalLordosisImage from '../../../assets/img/release_notes_seglord.jpg';
import T1PAT4PAImage from '../../../assets/img/release_notes_t1pa.jpg';
import MisImage from '../../../assets/img/release_notes_mis.jpg';

import {
  ReleaseNoteSubNote,
  ReleaseNoteMediaIdentifier,
} from '../../../models/release-note';
import { useReleaseNotesStore } from '../../../store/useReleaseNotesStore';

const releaseNotesStore = useReleaseNotesStore();

interface Props {
  subNote: ReleaseNoteSubNote;
}

const imageMap = {
  [ReleaseNoteMediaIdentifier.SegmentalLordosisScreenshot]:
    SegmentalLordosisImage,
  [ReleaseNoteMediaIdentifier.AxialRotationOfVertebraeScreenshot]:
    VertRotationChartImage,
  [ReleaseNoteMediaIdentifier.MisRodSupportScreenshot]: MisImage,
  [ReleaseNoteMediaIdentifier.MoreParametersScreenshot]: T1PAT4PAImage,
};

const getImageSource = (identifier: ReleaseNoteMediaIdentifier) => {
  return imageMap[identifier];
};

const getSubnoteClass = (subNote: ReleaseNoteSubNote) => {
  if (subNote.indentType === 'single') {
    return 'subnote-desc';
  } else if (subNote.indentType === 'double') {
    return 'subnote-sub-desc';
  }
};

const props = defineProps<Props>();
</script>

<template>
  <div v-if="subNote.description" :class="getSubnoteClass(subNote)">
    <span class="note-subtitle-ltr">{{ subNote.listMarker }}.</span>
    <div>{{ subNote.description }}</div>
  </div>

  <!-- Image handling -->
  <div v-if="subNote.media?.type === 'image'" class="subnote-desc subnote-img">
    <img
      v-if="subNote.media.identifier"
      :src="getImageSource(subNote.media.identifier as ReleaseNoteMediaIdentifier)"
      :alt="subNote.media.identifier"
      :style="subNote.media.styleProperties"
    />
  </div>

  <!-- Video handling -->
  <div v-if="subNote.media?.type === 'video'" class="subnote-desc">
    <span class="note-subtitle-ltr">{{ subNote.listMarker }}.</span>
    <video controls preload="metadata" class="note-video">
      <source :src="releaseNotesStore.currentlyViewedNote?.videoUrl" />
    </video>
  </div>
</template>

<style lang="scss" scoped>
.subnote-desc {
  display: flex;
  gap: 5px;
  margin-left: 10px;
}

.note-subtitle-ltr {
  font-weight: 500;
}

.subnote-sub-desc {
  display: flex;
  gap: 5px;
  margin-left: 25px;
}

.subnote-video {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  gap: 5px;
}

.subnote-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.note-video {
  width: 100%;
}
</style>
