export interface EosEndplate {
  endplate: EosEndplateEnum;
  vertebra: EosSpineLevelEnum;
}

export enum EosEndplateEnum {
  inferior = 'inferior',
  superior = 'superior',
}

export interface EosDeviationAndValue {
  deviation?: EosDeviation;
  value: number;
}

export enum EosPlaneEnum {
  FRONTAL = 'FRONTAL',
  LATERAL = 'LATERAL',
}

export enum EosUnitEnum {
  DEGREE = 'DEGREE',
  MM = 'MM',
  PERCENT = 'PERCENT',
}

export enum EosDeviation {
  WITHIN_STD = 'WITHIN_STD',
  BEYOND_2X_STD = 'BEYOND_2X_STD',
  AVERAGE = 'AVERAGE',
  BEYOND_1X_STD = 'BEYOND_1X_STD',
  HIGH = 'HIGH',
}

export enum EosSpineLevelEnum {
  C7 = 'C7',
  C6 = 'C6',
  C5 = 'C5',
  C4 = 'C4',
  C3 = 'C3',
  C2 = 'C2',
  C1 = 'C1',
  T12 = 'T12',
  T11 = 'T11',
  T10 = 'T10',
  T9 = 'T9',
  T8 = 'T8',
  T7 = 'T7',
  T6 = 'T6',
  T5 = 'T5',
  T4 = 'T4',
  T3 = 'T3',
  T2 = 'T2',
  T1 = 'T1',
  L5 = 'L5',
  L4 = 'L4',
  L3 = 'L3',
  L2 = 'L2',
  L1 = 'L1',
  S1 = 'S1',
}

export enum EosApproachEnum {
  ALIF = 'ALIF',
  PLIF = 'PLIF',
  TLIF = 'TLIF',
  LIF = 'LIF',
}

export interface EosImageData {
  view: 'FRONTAL' | 'LATERAL' | 'OTHER'; // other was added to support vea mobile and ioa
  imageData: string;
}

export enum ExamModelEnum {
  Planning = 'Planning',
  Alignment = 'Alignment',
  MobileAlignment = 'MobileAlignment',
  IoaReconciliation = 'IoaReconciliation',
}

export interface BmdAbout {
  dependencies: string;
  name: string;
  manufacturer: {
    name: string;
    address: string;
    phone: string;
  };
  ifu: {
    link: string;
  };
  md: {
    symbol: string;
  };
  rx: {
    symbol: string;
  };
  udi: {
    symbol: string;
    value: string;
  };
  version: string;
}
