import { AlignmentExamModel } from './eos-alignment-parameters';
import { PlanningExamModel } from './eos-planning-parameters';
import { RodOrder } from './rod-order';

export interface ApiList {
  array: Array<EosAcquisitionWithPatient | SurgicalPlanWithPatient>;
  isLoading: boolean;
  needsUpdating: boolean;
}

export interface EosAcquisition {
  id: string;
  refAcquisitionId: string;
  seriesDescription: string;
  instanceDateTimeUtc: string;
  isPostop: boolean;
  lastModifiedDateTimeUtc: string;
  createdDateTimeUtc: string;
  isPediatric: boolean | null;
  ageAtAcquisition: number | null;
  model?: AlignmentExamModel | PlanningExamModel;
}

export interface EosAlignment {
  id: string;
  refAcquisitionId: string;
  refModelingId: string;
  reportId: string;
  eosAlignmentStatus: string;
  isPostop: boolean;
  lastModifiedDateTimeUtc: string;
  createdDateTimeUtc: string;
  patient: PatientData;
}

export interface PatientData {
  atecId: string;
  dateOfBirth: string;
  firstName: string;
  id: string;
  institutionId: string;
  isActive: boolean;
  isFavorite: boolean;
  lastName: string;
  medicalRecordNumber: string;
  primaryPhysicianUserId: string;
  sex: string;
}

export interface EosAcquisitionWithPatient extends EosAcquisition {
  patient: PatientData;
}

export function calculateAge(dateOfBirth: Date) {
  const birthDate = dateOfBirth;
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function getPatientStatusItems(patient: SurgicalPlanWithPatient) {
  return {
    rodOrderStatus: patient.rodOrderStatus,
    surgery: patient.surgery,
    surgicalStrategyStatus: patient.surgicalStrategyStatus,
  };
}

export interface SurgicalPlanResponse {
  id: string;
  refAcquisitionId: string;
  refAuto3dModelingId: string;
  refPlannedModelingId: string;
  refLatestAlignmentModelingId: string;
  refLatestSaveId: string;
  reportId: string;
  rodOrderStatus?: RodOrderStatusType;
  surgicalStrategyStatus: SurgicalPlanStatusType;
  intakeForm?: IntakeForm;
  surgery?: Surgery;
  isRevision: boolean;
  lastModeifiedDateTimeUtc: string;
  createdDateTimeUtc: string;
}

export interface SurgicalPlanWithPatient extends SurgicalPlanResponse {
  patient: PatientData;
}

export enum RodOrderStatusType {
  Unset = 'Unset',
  Pending = 'Pending',
  Ordered = 'Ordered',
  Cancelled = 'Cancelled',
  NoRod = 'NoRod',
}

export enum SurgicalPlanStatusType {
  Unset = 'Unset',
  Initialized = 'Initialized',
  Processing = 'Processing',
  Processed = 'Processed',
  Returned = 'Returned',
  Approved = 'Approved',
}

export interface IntakeForm {
  id?: string;
  surgicalStrategyId?: string;
  createdDateTimeUtc?: string;
  lastModifiedDateTimeUtc?: string;
  upperInstrumentedVertebra: string;
  lowerInstrumentedVertebra: string;
  posteriorColumnOsteotomies: string[];
  pedicleSubtractionOsteotomies: string[];
  interbodies: Array<IntakeFormInterbody>;
  rod: IntakeFormRod | null;
}

export interface IntakeFormRod extends RodOrder {
  id?: string;
  intakeFormId?: string;
}

export interface IntakeFormInterbody {
  id: string;
  intakeFormId?: string;
  interbody: string;
  approach: string;
  system: string;
  footprint?: string;
}

export interface Surgery {
  patientId: string;
  physicianUserId: string;
  surgicalStrategyId: string | null;
  scheduledDateTimeUtc: string;
}

export interface SurgeryResponse {
  id: string;
  patientRecordId: string;
  surgicalStrategyId: string;
  refImplantsId: string;
  scheduledDateTimeUtc: string;
}

export enum SurgicalStrategyStatusDisplayType {
  NotSubmitted = 'Not Submitted',
  Submitted = 'Submitted',
  Requested = 'Requested',
  Returned = 'Returned',
  ModelReturned = 'Model Returned',
  NotAvailable = 'N/A',
}
export const strategyStatusDisplayTypeToSurgicalStrategyStatus = {
  NotSubmitted: 'Initialized',
  Submitted: ['Procesing', 'Processed', 'Approved'],
  Returned: 'Returned',
  NotAvailable: 'N/A',
};

export enum SurgicalPlanStatusDisplayType {
  PendingStrategy = 'Pending Strategy',
  Processing = 'Processing',
  NotApproved = 'Not Approved',
  Approved = 'Approved',
  NotRequested = 'Not Requested',
}
export const planDisplayTypeToSurgicalStrategyStatus = {
  PendingStrategy: 'Initialized',
  Processing: 'Processing',
  NotApproved: 'Processed',
  Approved: 'Approved',
};

export enum SurgicalDateDisplayType {
  Scheduled = 'Scheduled',
  NotScheduled = 'Not Scheduled',
}
export const dateDisplayTypeToScheduled = {
  Scheduled: true,
  NotScheduled: false,
};

export enum RodOrderStatusDisplayType {
  NoRod = 'No Rod',
  NotOrdered = 'Not Ordered',
  Ordered = 'Ordered',
  Canceled = 'Canceled',
  NotAvailable = 'N/A',
}
export const rodDisplayTypeToRodOrderStatus = {
  NoRod: 'NoRod',
  NotOrdered: 'Pending',
  Ordered: 'Ordered',
  Canceled: 'Cancelled',
};

export enum PatientBarStatusItemType {
  SurgicalStrategy = 'SurgicalStrategy',
  SurgicalPlan = 'SurgicalPlan',
  RodOrder = 'RodOrder',
  SurgeryDate = 'SurgeryDate',
}
