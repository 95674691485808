import { SessionStorageKeys } from '../models/session-storage';
import { WorkspaceLogger } from '../logger';
import { NotAGenericError } from '../models';
import {
  ExamResponse,
  PatientNoteResponseDto,
  PatientRecord,
  RecordResponse,
} from '../models/patient-exams';
import {
  IntakeForm,
  IntakeFormRod,
  PatientData,
  RodOrderStatusType,
  Surgery,
  SurgeryResponse,
  SurgicalPlanResponse,
} from '../models/patient-list';
import { UtilsService } from '../services';
import { useStore } from '../store';
import { ApplicationNameEnum } from 'models/application-launcher';
import { useSurgeonFeaturesForNonPhysicianUsers } from '../composables/useSurgeonFeaturesForNonPhysicianUsers';
import { ExamModelEnum } from '../models/eos-data';
import { MobileAlignmentData } from '../models/mobile-data';

export async function approveEOSAlignmentExam(
  eosAlignmentId: string
): Promise<RecordResponse> {
  const destUrl = `${location.origin}/api/v1/eos-alignments/${eosAlignmentId}/eos-alignment-status`;
  const response: RecordResponse = await fetch(destUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'physician-user-id': getPhysicianUserIdForHeader(),
    },
    body: JSON.stringify({
      eosAlignmentStatus: 'Approved',
    }),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function getPatientList(queryParams?: {
  [x: string]: string | number | boolean;
}): Promise<Array<ExamResponse>> {
  const destUrl = `${
    location.origin
  }/api/v1/patients/exams/latest${UtilsService.buildQueryString(queryParams)}`;
  const exams = await fetch(destUrl, {
    headers: {
      'Content-Type': 'application/json',
      'physician-user-id': getPhysicianUserIdForHeader(),
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);

  return exams;
}

export async function getPatientRecord(
  patientRecordId?: string
): Promise<PatientRecord> {
  const destUrl = `${location.origin}/api/v1/patient-records/${patientRecordId}`;
  const exam: PatientRecord = await fetch(destUrl, {
    headers: {
      'Content-Type': 'application/json',
      'physician-user-id': getPhysicianUserIdForHeader(),
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) => UtilsService.handleCaughtErrors(err));

  return exam;
}

export async function getSurgicalCandidates(queryParams?: {
  [x: string]: string[] | string | number | boolean;
}): Promise<Array<ExamResponse>> {
  const destUrl = `${
    location.origin
  }/api/v1/patients/exams/surgical-candidates${UtilsService.buildQueryString(
    queryParams
  )}`;
  const exams: Array<ExamResponse> = await fetch(destUrl, {
    headers: {
      'Content-Type': 'application/json',
      'physician-user-id': getPhysicianUserIdForHeader(),
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);

  return exams;
}

export async function getCalendarCandidates(queryParams?: {
  [x: string]: string[] | string | number | boolean;
}): Promise<Array<ExamResponse>> {
  const destUrl = `${
    location.origin
  }/api/v1/patients/exams${UtilsService.buildQueryString(queryParams)}`;
  const exams: Array<ExamResponse> = await fetch(destUrl, {
    headers: {
      'Content-Type': 'application/json',
      'physician-user-id': getPhysicianUserIdForHeader(),
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);

  return exams;
}

export async function getSurgicalPlan(
  surgicalPlanId: string
): Promise<SurgicalPlanResponse | undefined> {
  const destUrl = `${location.origin}/api/v1/surgical-strategies/${surgicalPlanId}`;
  const response = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);

  return response;
}

export async function scheduleSurgery(
  surgery: Surgery
): Promise<SurgeryResponse> {
  const destUrl = `${location.origin}/api/v1/surgeries`;
  const response = await fetch(destUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(surgery),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function rescheduleSurgery(
  surgeryId: string,
  scheduledDateTime: string
): Promise<SurgeryResponse> {
  const destUrl = `${location.origin}/api/v1/surgeries/${surgeryId}/scheduled-datetime`;
  const response = await fetch(destUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ scheduledDateTimeUtc: scheduledDateTime }),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function submitSurgicalPlan(
  surgicalPlanId: string,
  intakeForm: IntakeForm
): Promise<SurgicalPlanResponse> {
  const destUrl = `${location.origin}/api/v1/surgical-strategies/${surgicalPlanId}/intake-forms`;
  const response = await fetch(destUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(intakeForm),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function submitSurgicalPlanBlankPlan(
  surgicalPlanId: string
): Promise<SurgicalPlanResponse> {
  return submitSurgicalPlan(surgicalPlanId, {
    interbodies: [],
    lowerInstrumentedVertebra: '',
    pedicleSubtractionOsteotomies: [],
    posteriorColumnOsteotomies: [],
    rod: null,
    upperInstrumentedVertebra: '',
  });
}

export async function submitRodOrder(
  surgicalPlanId: string,
  intakeFormID: string,
  rodOrder: IntakeFormRod
): Promise<SurgicalPlanResponse> {
  const destUrl = `${location.origin}/api/v1/surgical-strategies/${surgicalPlanId}/intake-forms/${intakeFormID}/rods`;
  const response = await fetch(destUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(rodOrder),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function editRodOrder(
  surgicalPlanId: string,
  intakeFormID: string,
  intakeFormRodId: string,
  rodOrder: IntakeFormRod
): Promise<SurgicalPlanResponse> {
  const destUrl = `${location.origin}/api/v1/surgical-strategies/${surgicalPlanId}/intake-forms/${intakeFormID}/rods/${intakeFormRodId}`;
  const response = await fetch(destUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(rodOrder),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function deleteRodOrder(
  surgicalPlanId: string,
  intakeFormID: string,
  intakeFormRodId: string
): Promise<SurgicalPlanResponse> {
  const destUrl = `${location.origin}/api/v1/surgical-strategies/${surgicalPlanId}/intake-forms/${intakeFormID}/rods/${intakeFormRodId}`;
  const response = await fetch(destUrl, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function approveSurgicalPlan(
  surgicalPlanId: string,
  refPlannedModelingId: string
): Promise<SurgicalPlanResponse> {
  const destUrl = `${location.origin}/api/v1/surgical-strategies/${surgicalPlanId}/surgical-strategy-status`;
  const response = await fetch(destUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      surgicalStrategyStatus: 'Approved',
      refPlannedModelingId: refPlannedModelingId,
    }),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function getPatientRecords(queryParams?: {
  [x: string]: string | number | string[];
}): Promise<Array<PatientRecord>> {
  const destUrl = `${
    location.origin
  }/api/v1/patient-records${UtilsService.buildQueryString(queryParams)}`;
  const exams: Array<PatientRecord> = await fetch(destUrl, {
    headers: {
      'Content-Type': 'application/json',
      'physician-user-id': getPhysicianUserIdForHeader(),
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);

  return exams;
}

export async function getGlobalSearchResults(queryParams?: {
  [x: string]: string | number;
}): Promise<Array<PatientData>> {
  const destUrl = `${
    location.origin
  }/api/v1/patients/lookahead${UtilsService.buildQueryString(queryParams)}`;
  const response: Array<PatientData> = await fetch(destUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function getPatientInformation(
  patientId: string
): Promise<ExamResponse> {
  const destUrl = `${location.origin}/api/v1/patients/${patientId}/exams/latest`;
  const response: ExamResponse = await fetch(destUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function toggleFavoritePatient(
  patientId: string,
  value: boolean
): Promise<PatientData> {
  const destUrl = `${location.origin}/api/v1/patients/${patientId}/is-favorite`;
  const response: PatientData = await fetch(destUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'physician-user-id': getPhysicianUserIdForHeader(),
    },
    body: JSON.stringify({
      isFavorite: value,
    }),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function linkPatient(
  patientId: string,
  linkToPrimary: boolean
): Promise<PatientData> {
  const destUrl = `${location.origin}/api/v1/patients/${patientId}/primary-physician-id`;
  const response: PatientData = await fetch(destUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Physician-User-Id': linkToPrimary ? getPhysicianUserIdForHeader() : '',
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function acknowledgeAlignmentExam(
  eosAlignmentId: string
): Promise<PatientData> {
  const destUrl = `${location.origin}/api/v1/eos-alignments/${eosAlignmentId}/user-validated`;
  const response: PatientData = await fetch(destUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Physician-User-Id': getPhysicianUserIdForHeader(),
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function getAllNotesForPatient(
  patientId: string
): Promise<Array<PatientNoteResponseDto>> {
  const queryParams = {
    patientId,
    page: 1,
    limit: 50,
  };

  const destUrl = `${
    location.origin
  }/api/v1/patient-notes${UtilsService.buildQueryString(queryParams)}`;
  const response: Array<PatientNoteResponseDto> = await fetch(destUrl, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function addNoteToPatient(
  patientId: string,
  note: string
): Promise<Array<PatientNoteResponseDto>> {
  const destUrl = `${location.origin}/api/v1/patient-notes`;
  const response: Array<PatientNoteResponseDto> = await fetch(destUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id': getPhysicianUserIdForHeader(),
    },
    body: JSON.stringify({ patientId, note }),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function viewPatientNote(
  noteId: string
): Promise<Array<PatientNoteResponseDto>> {
  const destUrl = `${location.origin}/api/v1/patient-notes/${noteId}/patient-note-view`;
  const response: Array<PatientNoteResponseDto> = await fetch(destUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id': getPhysicianUserIdForHeader(),
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function editNote(
  noteId: string,
  note: string
): Promise<Array<PatientNoteResponseDto>> {
  const destUrl = `${location.origin}/api/v1/patient-notes/${noteId}`;
  const response: Array<PatientNoteResponseDto> = await fetch(destUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ note }),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function deleteNote(
  noteId: string,
  note: string
): Promise<Array<PatientNoteResponseDto>> {
  const destUrl = `${location.origin}/api/v1/patient-notes/${noteId}`;
  const response: Array<PatientNoteResponseDto> = await fetch(destUrl, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ note }),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function patchRevisionSurgery(
  surgicalStrategyId: string,
  isRevision: boolean
): Promise<Array<PatientNoteResponseDto>> {
  const destUrl = `${location.origin}/api/v1/surgical-strategies/${surgicalStrategyId}/is-revision`;
  const response: Array<PatientNoteResponseDto> = await fetch(destUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ isRevision }),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function getAppLaunchUrl(
  appName: ApplicationNameEnum
): Promise<string | undefined> {
  const destUrl = `${location.origin}/api/v1/application-launcher?applicationName=${appName}`;
  const response = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.text())
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
      throw new Error(err);
    });

  return response;
}

export function isPatientRodOrdered(patientData: ExamResponse) {
  if (!patientData) {
    return false;
  }

  if (
    patientData.surgicalStrategy?.rodOrderStatus === null ||
    patientData.surgicalStrategy?.rodOrderStatus === RodOrderStatusType.NoRod ||
    patientData.surgicalStrategy?.intakeForm == null
  ) {
    return false;
  }

  if (patientData.surgicalStrategy) {
    const rodOrderStatus = patientData.surgicalStrategy?.rodOrderStatus;
    const intakeFormExists =
      Object.keys(patientData.surgicalStrategy.intakeForm).length > 0;

    if (intakeFormExists && rodOrderStatus && rodOrderStatus !== 'Unset') {
      return true;
    }
  }
  return false;
}

export function getPhysicianUserIdForHeader() {
  const store = useStore();
  const { selectedPhysicianUserId } = useSurgeonFeaturesForNonPhysicianUsers();

  return selectedPhysicianUserId.value ?? store.currentUserInfo.userId;
}

export function getPatientRecordHub(): string {
  return `${location.origin}/patientRecordHub`;
}

export function getAlignmentHub(): string {
  return `${location.origin}/alignmentHub`;
}
