import { Case3dWithIntakeForm } from '../models/3d-services';
import { WorkspaceLogger } from '../logger';
import { ErrorWithStatus } from '../models/error';
import {
  ModelImage,
  PatientNoteResponseWithUserDto,
  PatientNoteWithUser,
  PatientTrendsData,
} from '../models/patient-exams';
import { UtilsService } from '../services';
import { getPhysicianUserIdForHeader } from '../services/patient-history-service';
import { useStore } from '../store';
import { PatientData } from '../models/patient-list';
import { AlignmentExamModel } from '../models/eos-alignment-parameters';
import { PlanningExamModel } from '../models/eos-planning-parameters';
import { ExamModelEnum } from '../models/eos-data';
import { AlignmentParameters } from '../models/alignment-parameters';
import { MobileAlignmentData } from '../models/mobile-data';
import { IoaReconciliationResponse } from '../models/ioa-data';
import { BmdAbout } from '../models/eos-data';
import { ReleaseNote } from '../models/system';
export async function getAlignmentParameters(
  modelingId: string
): Promise<AlignmentExamModel> {
  const destUrl = `${location.origin}/api/v1/portal/modelings/${modelingId}`;
  const response: AlignmentExamModel = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) =>
      UtilsService.handleCaughtErrors(err, [
        404,
        423,
        'Response status code does not indicate success: 423 (Locked)',
        'Response status code does not indicate success: 423 (Locked).',
      ])
    );
  response.clinicalParameters.modelType = ExamModelEnum.Alignment;
  return response;
}

export async function getPreviews(
  acquisitionId: string
): Promise<AlignmentExamModel> {
  const queryParams = {
    size: 'Medium',
  };
  const destUrl = `${
    location.origin
  }/api/v1/portal/acquisitions/${acquisitionId}/previews${UtilsService.buildQueryString(
    queryParams
  )}`;
  const response: AlignmentExamModel = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function getPlanningParameters(
  saveId: string,
  acquisitionId: string
): Promise<PlanningExamModel> {
  const destUrl = `${location.origin}/api/v1/portal/planning/${saveId}?acquisitionId=${acquisitionId}`;
  const response: PlanningExamModel = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  response.clinicalParameters.modelType = ExamModelEnum.Planning;
  return response;
}

export async function getPatientTrends(
  patientId: string
): Promise<Array<PatientTrendsData>> {
  const store = useStore();

  const queryParams = {
    patientId: patientId,
    physicianId: store.currentUserInfo.userId,
    limit: 50,
    page: 1,
  };
  const destUrl = `${
    location.origin
  }/api/v1/portal/trends${UtilsService.buildQueryString(queryParams)}`;
  const response: Array<PatientTrendsData> = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => {
      if (res.status === 204) {
        //
      } else {
        return res.json();
      }
    })
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function getImageDataAndParameters(queryParams?: {
  [x: string]: string | number;
}): Promise<{
  modelingId: string;
  images: Array<ModelImage>;
  clinicalParameters: AlignmentParameters;
}> {
  const destUrl = `${
    location.origin
  }/api/v1/portal/alignment${UtilsService.buildQueryString(queryParams)}`;
  const response: {
    modelingId: string;
    images: Array<ModelImage>;
    clinicalParameters: AlignmentParameters;
  } = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function getMobileImageAndAlignmentParameters(
  mobileAlignmentId: string
): Promise<MobileAlignmentData> {
  const destUrl = `${location.origin}/api/v1/mobile-alignments/${mobileAlignmentId}`;
  const response: any = await fetch(destUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);

  response.modelType = ExamModelEnum.MobileAlignment;

  return response;
}

export async function getIoaImagesAndAlignmentParameters(
  reconciliationId: string
): Promise<IoaReconciliationResponse> {
  const destUrl = `${location.origin}/api/v1/portal/ioa/reconciliations/${reconciliationId}`;
  const response: any = await fetch(destUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);

  response.modelType = ExamModelEnum.IoaReconciliation;

  return response;
}

export async function getPatientNotes(queryParams?: {
  [x: string]: string | number | boolean;
}): Promise<PatientNoteResponseWithUserDto> {
  const destUrl = `${
    location.origin
  }/api/v1/portal/patient-notes${UtilsService.buildQueryString(queryParams)}`;
  const resp: PatientNoteResponseWithUserDto = await fetch(destUrl, {
    headers: {
      'Content-Type': 'application/json',
      'user-id': getPhysicianUserIdForHeader(),
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);

  return resp;
}

export async function getPhysicianPreferences(
  physicianId: string
): Promise<any> {
  const destUrl = `${location.origin}/api/v1/portal/preferences/${physicianId}`;
  const response: any = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);
  return response;
}

export async function getGlobalSearchResults(queryParams?: {
  [x: string]: string | number;
}): Promise<{
  [patients: string]: Array<PatientData>;
}> {
  const destUrl = `${
    location.origin
  }/api/v1/portal/patients/lookahead${UtilsService.buildQueryString(
    queryParams
  )}`;
  const response: {
    [patients: string]: Array<PatientData>;
  } = await fetch(destUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => {
      if (res.status === 204) {
        return {};
      } else {
        return res.json();
      }
    })
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
      throw new ErrorWithStatus(err.message, err.status);
    });
  return response;
}

export async function getBmdAbout(): Promise<BmdAbout> {
  const destUrl = `${location.origin}/api/v1/bmd/about`;
  const resp: BmdAbout = await fetch(destUrl, {
    headers: {
      'Content-Type': 'application/json',
      'user-id': getPhysicianUserIdForHeader(),
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);

  return resp;
}

export async function getAllReleaseNotes(): Promise<ReleaseNote[]> {
  const destUrl = `${location.origin}/api/v1/portal/system/release-versions`;
  const resp: ReleaseNote[] = await fetch(destUrl, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);

  return resp;
}

export async function hasUserAcknowledgedLatestVersion(): Promise<{
  hasAckdLatestVersion: boolean;
  latestReleaseVersion: ReleaseNote | null;
}> {
  const destUrl = `${location.origin}/api/v1/portal/system/release-versions/user-acknowledgement`;
  const resp = await fetch(destUrl, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch(UtilsService.handleCaughtErrors);

  return resp;
}
